import OkinawaImage from "../img/_TAK3617-2.jpg";

export const Top = () => {
  return (
    <>
      <div className="mx-8 text-center">
        <h2>Top</h2>
        <img src={OkinawaImage} alt="okinawa_image" />
      </div>
    </>
  );
};
