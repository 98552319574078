export const NotFound = () => {
    return (
      <>
        <div className="text-center">
                <h2>404エラー</h2>
                <p>お探しのページは見つかりません。</p>
        </div>
      </>
    );
  };
  